import React, { useState } from 'react';
import './App.css';

function App() {
  const [lists, setLists] = useState([]);
  const [inputValue, setInputValue] = useState('');

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleAddList = () => {
    if (inputValue.trim() !== '') {
      setLists([...lists, inputValue]);
      setInputValue('');
    }
  };

  const handleDeleteList = (index) => {
    const updatedLists = lists.filter((_, i) => i !== index);
    setLists(updatedLists);
  };

  return (
    <div className="App">
      <h1>To-Do List</h1>
      <div className="input-container">
        <input
          type="text"
          placeholder="Enter your daily to-do task..."
          value={inputValue}
          onChange={handleInputChange}
        />
        <button onClick={handleAddList}>Add Task</button>
      </div>
      <ul>
        {lists.map((list, index) => (
          <li key={index}>
            {list}
            <button onClick={() => handleDeleteList(index)}>Delete</button>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default App;
